@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

*:focus,
*:active {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#main-content {
  background-color: #f3f3f3;
  height: calc(100vh - 50px);
  padding: 25px;
  overflow-y: scroll;
}

.bx--header__menu-item,
.bx--side-nav__item {
  cursor: pointer;
}

.empty-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: rgb(175, 175, 175);
  margin: auto;
}

.red-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #e53935;
  margin: auto;
}

.yellow-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #fb8c00;
  margin: auto;
}

.green-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #43a047;
  margin: auto;
}

.TabPanelOnlyTopPadding .MuiBox-root {
  padding-left: 0px;
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.TabPanelOnlyHalfTopPadding .MuiBox-root {
  padding-left: 0px;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.SubTabPanel {
  width: 100%;
}

.SubTabPanel .MuiBox-root {
  padding-left: 0px;
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.MuiTab-root:focus {
  outline: none;
}

.overflow-hidden {
  overflow: hidden;
}

.MuiButton-root.btn-special {
  background: linear-gradient(
    45deg,
    rgb(232, 55, 105) 30%,
    rgb(110, 68, 142) 90%
  );
  box-shadow: rgba(232, 55, 105, 0.15) 0px 3px 5px 2px;
  color: white;
  padding: 8px 16px;
}

.calendar .months-container .month-container {
  height: 245px !important;
}

.calendar .calendar-header {
  margin-bottom: 40px !important;
}

.calendar table td,
.calendar table th {
  font-size: 15px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiTab-root {
  max-width: none !important;
  text-transform: none !important;
}

.noWrap {
  white-space: nowrap !important;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.check-long-text span.MuiFormControlLabel-label {
  font-size: small;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 8px 0;
  background-color: #000000;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 14px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  /* text-transform: uppercase; */
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -2px;
  top: 27px;
  transform: rotate(45deg);
}

.ribbon-top-right-revision {
  top: -10px;
  right: 0px;
}

.ribbon-top-right-revision::before,
.ribbon-top-right-revision::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right-revision::before {
  top: 0;
  left: 0;
}

.ribbon-top-right-revision::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right-revision span {
  left: -2px;
  top: 27px;
  transform: rotate(45deg);
}

.ribbon.purpleAndWhite span {
  background-color: #552877;
  color: #fff;
}

.ribbon.yellowAndBlack span {
  background-color: #f9a825;
  color: #000;
}

.ribbon.greyAndWhite span {
  background-color: #616160;
  color: #fff;
}

